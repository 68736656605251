import { useApi } from '@/api';
import { useDuplicateWithoutStats, useEnrichedDetail } from '@/composables';
import { useFilterStore } from '@/stores/filter';
import { useListingStore } from '@/stores/listing';
import {
  EnrichedListingDetail,
  ListingDetail,
  ListingId,
  Product,
} from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

/**
 * This store deals with relevant listings for the current logged-in user, relevant listings are:
 * - listings of the user company
 * - listings that match user company factories
 *
 * Listings are added via load method or events:
 *   -> drafts of matching listings (only via events)
 *   -> canceled and completed (only via events)
 */
export const useListingDetailStore = defineStore('listing-detail', () => {
  const api = useApi();
  const mapped = ref<Record<ListingId, EnrichedListingDetail>>({});
  const listings = computed(() => {
    return useFilterStore().apply(Object.values(mapped.value));
  });

  const put = (listing: ListingDetail) => {
    mapped.value[listing.id] = useEnrichedDetail(listing);
  };

  const get = (id: ListingId): EnrichedListingDetail => {
    return mapped.value[id];
  };

  const getThen = (
    id: ListingId,
    then: (listing: EnrichedListingDetail) => void
  ) => {
    const existing = mapped.value[id] ?? undefined;

    if (existing) {
      then(existing);
    }
  };

  const remove = (listing: { id: ListingId }) => {
    delete mapped.value[listing.id];
  };

  const complete = (listing: { id: ListingId }) => {
    getThen(listing.id, (existing) => {
      put({
        ...existing,
        status: 'COMPLETED',
        volume: { ...existing.volume, amount: 0 },
      });
    });
  };

  const cancel = (listing: { id: ListingId }) => {
    getThen(listing.id, (existing) => {
      put({
        ...existing,
        status: 'CANCELED',
      });
    });
  };

  const draft = (listing: { id: ListingId }) => {
    getThen(listing.id, (existing) => {
      put(
        useDuplicateWithoutStats({
          ...existing,
          status: 'DRAFT',
        })
      );
    });
  };

  const clear = () => {
    mapped.value = {};
  };

  const load = async (product: Product): Promise<void> => {
    const loaded = await api.listing.getRelevant(product);
    loaded.forEach((listing) => put(listing));
    useListingStore().computeActiveHistory();
  };

  return {
    listings,
    get,
    put,
    load,
    remove,
    complete,
    cancel,
    draft,
    clear,
  };
});
