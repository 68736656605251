import generalListingCanceledHandlers from '@/notifications/general/general-listing-canceled-handlers';
import generalListingCompletedHandlers from '@/notifications/general/general-listing-completed-handlers';
import generalListingPublishedHandlers from '@/notifications/general/general-listing-published-handlers';
import generalListingUnpublishedHandlers from '@/notifications/general/general-listing-unpublished-handlers';
import generalMarketDealPreviewCreated from '@/notifications/general/general-market-deal-preview-created-handlers';
import generalReloadStoreHandler from '@/notifications/general/general-reload-store-handler';
import { EventHandler } from '@/types/notification';

export const generalEventsHandlers: Record<string, EventHandler[]> = {
  'Backoffice.ReloadStore': generalReloadStoreHandler,
  'Marketplace.ListingCanceled': generalListingCanceledHandlers,
  'Marketplace.ListingCompleted': generalListingCompletedHandlers,
  'Marketplace.ListingPublished': generalListingPublishedHandlers,
  'Marketplace.ListingUnpublished': generalListingUnpublishedHandlers,
  'Marketplace.MarketDealPreviewCreated': generalMarketDealPreviewCreated,
};
