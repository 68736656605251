import { useIsAllowedIncoterm } from '@/composables/shipment';
import { useUserCanBuy, useUserCanSell } from '@/composables/user-roles';
import { useUserStore } from '@/stores';
import {
  Company,
  EnrichedListingDetail,
  EnrichedListingPreview,
  Incoterm,
  ListingDetail,
  ListingPreview,
  ListingType,
} from '@/types';

export function useIsApprovedListing(listing: ListingDetail): boolean {
  const company = useUserStore().company;

  if (!company || company.id === listing.owner.id) {
    return false;
  }

  const matchedFactories = listing.matches[company.id] ?? [];

  return matchedFactories.length > 0;
}

export function useListingHasAvailableVolume(listing: ListingDetail): boolean {
  return listing.volume.amount > 0;
}

export function useIsBidListing(
  listing: ListingDetail | ListingPreview
): boolean {
  return listing.type === 'BID';
}

export function useIsOfferListing(
  listing: ListingDetail | ListingPreview
): boolean {
  return listing.type === 'OFFER';
}

export function useIsPublishedListing(
  listing: ListingDetail | ListingPreview
): boolean {
  return listing.status === 'PUBLISHED';
}

export function useIsDraftListing(
  listing: ListingDetail | ListingPreview
): boolean {
  return listing.status === 'DRAFT';
}

export function useIsOngoingListing(listing: ListingDetail): boolean {
  return listing.stats.totalOngoing > 0;
}

export function useIsNotOngoingListing(listing: ListingDetail): boolean {
  return !useIsOngoingListing(listing);
}

export function useBestPriceOf(listing: EnrichedListingDetail) {
  const best = useIsBidListing(listing)
    ? // If the bid is mine, the best I can receive is the lowestCounterOffer.
      listing.isYourListing
      ? listing.stats.lowestCounterOffer
      : listing.stats.highestCounterBid
    : // If the offer is mine, the best I can receive is the highestCounterBid.
      listing.isYourListing
      ? listing.stats.highestCounterBid
      : listing.stats.lowestCounterOffer;

  return best ?? listing.unitPrice;
}

export function useEnrichedDetail(
  listing: ListingDetail
): EnrichedListingDetail {
  return {
    ...listing,
    isYourListing: useUserStore().isMyCompany(listing.owner.id),
  };
}

export function useEnrichedPreview(
  listing: ListingPreview
): EnrichedListingPreview {
  return {
    ...listing,
    isYourListing: useUserStore().isMyCompany(listing.owner.id),
  };
}

export function useGetListingTypeForCompany(
  type: ListingType,
  company?: Company
): ListingType | undefined {
  if (company === undefined) {
    return undefined;
  }

  if (type === 'BID' && !useUserCanBuy(company)) {
    return 'OFFER';
  }

  if (type === 'OFFER' && !useUserCanSell(company)) {
    return 'BID';
  }

  return type;
}

export function useGetIncotermForListingType(
  type: ListingType,
  incoterm: Incoterm
): Incoterm | undefined {
  return useIsAllowedIncoterm(type, incoterm) ? incoterm : undefined;
}

export const useDuplicateWithoutStats = <T extends ListingDetail>(
  listing: T
): T => {
  return {
    ...listing,
    stats: {
      totalOngoing: 0,
      waitingResponse: 0,
      lowestCounterOffer: undefined,
      highestCounterBid: undefined,
    },
  };
};
