import companyDealDetailCreatedHandlers from '@/notifications/company/company-deal-detail-created-handlers';
import companyListingDetailGeneratedHandlers from '@/notifications/company/company-listing-detail-generated-handlers';
import companyListingNegotiationsUpdatedHandlers from '@/notifications/company/company-listing-negotiations-updated-handlers';
import companyNegotiationPausedHandlers from '@/notifications/company/company-negotiation-paused-handlers';
import companyNegotiationWithdrawnHandlers from '@/notifications/company/company-negotiation-withdrawn-handlers';
import proposalCreatedHandlers from '@/notifications/company/company-proposal-created-handlers';
import { EventHandler } from '@/types/notification';

export const companyEventsHandlers: Record<string, EventHandler[]> = {
  'Marketplace.CompanyDealDetailCreated': companyDealDetailCreatedHandlers,
  'Marketplace.ListingDetailGenerated': companyListingDetailGeneratedHandlers,
  'Marketplace.ListingNegotiationsUpdated':
    companyListingNegotiationsUpdatedHandlers,
  'Marketplace.NegotiationPaused': companyNegotiationPausedHandlers,
  'Marketplace.NegotiationWithdrawn': companyNegotiationWithdrawnHandlers,
  'Marketplace.ProposalCreated': proposalCreatedHandlers,
};
